import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import styles from '../../styles/styles';


export default function Team() {
  return (
    <Grid spacing={3} container sx={styles.container1}>

      <Grid item xl={6} md={6} xs={12}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", pb: 1 }} variant="h6">Екип</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 1 }} variant="h4">Запознай се с нашия
          <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main" }}> екип</Typography>
        </Typography>
        <Typography>Маркетинг специалисти, уеб дизайнери, видеооператори, специалисти по данни, разработчици... цялата експертиза, необходима в дигиталния свят.
        </Typography>
      </Grid>

      <Grid container item xl={12} md={6} xs={12} alignItems='flex-start' justifyContent='flex-start'>
        <Button variant="contained" color="primary" sx={{ size: 'large', borderRadius: 8, fontSize: 13, my: 3, py: 1.2, mr: 2 }}>LinkedIn</Button>
        <Button component={Link} variant="contained" to='/carreers#positions'  color="primary" sx={{ size: 'large', borderRadius: 8, fontSize: 13, my: 3, py: 1.2 }}>Свободни позиции</Button>
      </Grid>      

      <Grid item xl={3} md={4} sm={6} xm={6} xs={12} sx={{mt: -2}}>
        <Grid container sx={{ pt: 3, backgroundColor: '#fafcff', border: '1px solid #d4d4d4', borderRadius: 5 }} direction='column' justifyContent='center' alignItems='center'>
          <Avatar sx={{ width: 64, height: 64, border: 2, borderColor: 'primary.main' }} src="/boss.jpg"></Avatar>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 1 }}>Васил Боснешки</Typography>
          <Typography sx={{ fontSize: 14, pb: 2 }}>CEO</Typography>
          <Typography sx={{ fontSize: 14, py: 1, px: 2, borderTop: '1px solid #d4d4d4' }}>👔+🎮+🚀=💡 Бизнес иновации, космически мечти и един шоколадов лабрадор 🐕</Typography>
        </Grid>
      </Grid>

      <Grid item xl={3} md={4} sm={6} xm={6} xs={12} sx={{mt: -2}}>
        <Grid container sx={{ pt: 3, border: '1px solid #d4d4d4', borderRadius: 5 }} direction='column' justifyContent='center' alignItems='center'>
           <Avatar sx={{ width: 64, height: 64, border: 2, borderColor: 'primary.main' }} src="/tisho1.jpg"></Avatar>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 1 }}>Тихомир Блажев</Typography>
          <Typography sx={{ fontSize: 14, pb: 2 }}>Head of IT Department</Typography>
          <Typography sx={{ fontSize: 14, py: 1, px: 2, borderTop: '1px solid #d4d4d4' }}>Свиря на 🎸, любител съм на тежката музика и групи като Slipknot 🤘</Typography>
        </Grid>
      </Grid>

      <Grid item xl={3} md={4} sm={6} xm={6} xs={12} sx={{mt: -2}}>
        <Grid container sx={{ pt: 3, border: '1px solid #d4d4d4', borderRadius: 5 }} direction='column' justifyContent='center' alignItems='center'>
           <Avatar sx={{ width: 64, height: 64, border: 2, borderColor: 'primary.main' }} src="/belon1.jpg"></Avatar>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 1 }}>Белон Манукян</Typography>
          <Typography sx={{ fontSize: 14, pb: 2 }}>Full-Stack Developer</Typography>
          <Typography sx={{ fontSize: 14, py: 1, px: 2, borderTop: '1px solid #d4d4d4' }}>Любител на 🌐 технологиите, срещите с приятели 🎉 и пътешествията 🌍</Typography>
        </Grid>
      </Grid>      

      <Grid item xl={3} md={4} sm={6} xm={6} xs={12} sx={{mt: -2}}>
        <Grid container sx={{ pt: 3, border: '1px solid #d4d4d4', borderRadius: 5 }} direction='column' justifyContent='center' alignItems='center'>
           <Avatar sx={{ width: 64, height: 64, border: 2, borderColor: 'primary.main' }} src="/petya1.jpg"></Avatar>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 1 }}>Петя Цветанова </Typography>
          <Typography sx={{ fontSize: 14, pb: 2 }}>WordPress Developer</Typography>
          <Typography sx={{ fontSize: 14, py: 1, px: 2, borderTop: '1px solid #d4d4d4' }}>Обожавам да изучавам нови езици 🌍 и да пътувам, като 🇪🇸 е моята любима дестинация ✈️</Typography>
        </Grid>
      </Grid>

      <Grid item xl={3} md={4} sm={6} xm={6} xs={12} sx={{mt: -2}}>
        <Grid container sx={{ pt: 3, border: '1px solid #d4d4d4', borderRadius: 5 }} direction='column' justifyContent='center' alignItems='center'>
           <Avatar sx={{ width: 64, height: 64, border: 2, borderColor: 'primary.main' }} src="/nelly1.jpg"></Avatar>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', pt: 1 }}>Сема Мюмюн </Typography>
          <Typography sx={{ fontSize: 14, pb: 2 }}>Graphic designer</Typography>
          <Typography sx={{ fontSize: 14, py: 1, px: 2, borderTop: '1px solid #d4d4d4' }}>Приключенец по душа 🌍, адреналинови предизвикателства🏂🌊 и безнадеждно влюбена във френските булдози 🐾❤.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}    